import React from "react";
//
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chart from "./Chart";
import { getReportUrl } from "../utils";
const EndTest = ({ xValue, yValue, style, report }) => {
  return (
    <CardContent>
      <Typography variant="h6" component="div" align="center">
        {style.title}
      </Typography>
      <Typography variant="h5" component="div" align="center">
        Results
      </Typography>
      <Chart xValue={xValue} yValue={yValue} style={style} />
      <Typography variant="body1" sx={{ mt: 1.5 }} align="left">
        <a
          href={getReportUrl(report.report_id)}
          rel="noopener noreferrer"
          target="_blank"
        >
          Download
        </a>{" "}
        your personalized report to learn more about your preferred style, with
        its strengths and weaknesses and what you can do to develop other
        styles.
      </Typography>
    </CardContent>
  );
};

export default EndTest;
