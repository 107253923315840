import React, { useEffect, useState } from "react";
import Template from "./components/Template";
import { useDataProvider } from "react-admin";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Box, Card } from "@mui/material";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await dataProvider.getList("courses", {
        pagination: { page: 1, perPage: 1000 },
        sort: {},
      });
      setCourses(data);
      setLoading(false);
    })();
  }, []);
  const Course = ({ id, image, title, description }) => {
    return (
      <Card
        sx={{
          marginBottom: "30px",
        }}
      >
        <div className="course-card row align-items-center">
          <div className="col-12 col-lg-3">
            <div
              style={{
                height: 180,
                backgroundImage: `url('${image.src}')`,
              }}
              className="course-img"
            ></div>
          </div>
          <div className="col-12 col-lg-7">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
          <div className="col-12 col-lg-2">
            <Button
              size="lg"
              variant="contained"
              onClick={() => navigate("/my_courses/" + id)}
            >
              View Course
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Template
        header={{
          title: "Your Learning Dashboard",
        }}
        title={"My Courses"}
        contentLoading={loading}
      >
        {courses.length > 0 ? (
          courses.map((course, index) => <Course key={index} {...course} />)
        ) : (
          <Card>
            <Box p={2}>Sorry, no courses found.</Box>
          </Card>
        )}
      </Template>
    </>
  );
};

export default Courses;
