import { app } from "../constants";
import jwt from "jwt-decode";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // called when the user attempts to log in
  login: ({ email, password }) => {
    const request = new Request(app.api + "jwt/create/", {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        let token = auth.access;
        let user_id = jwt(token).user_id;
        let role = jwt(token).role;
        localStorage.setItem("auth", JSON.stringify({ token, user_id, role }));
      })
      .catch((error) => {
        throw new Error("Invalid credentials.");
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ response }) => {
    const status = response?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      return Promise.resolve(auth);
    }
    return Promise.reject();
  },
  getIdentity: () => {
    var token = {};
    if (localStorage.getItem("auth"))
      token = JSON.parse(localStorage.getItem("auth"));
    return Promise.resolve({
      user_id: token.user_id,
      token: token?.token,
      role: token?.role,
    });
  },
};
