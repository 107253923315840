import React from "react";
import { Card } from "@mui/material";

const CourseProgress = ({ course }) => (
  <Card sx={{ padding: 2 }}>
    <center>
      <img
        src={course.image.src}
        className="img-fluid"
        style={{
          width: "65%",
        }}
      />
    </center>
    <h5
      className="mb-0"
      style={{ fontSize: "1em", marginTop: 5, textAlign: "center" }}
    >
      {(course?.user_lessons || []).length} of{" "}
      {(course?.all_lessons || []).length} Lessons Completed
    </h5>
  </Card>
);
export default CourseProgress;
