import axios from "axios";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { Link } from "react-router-dom";
import { app } from "../admin/constants";

export default function PasswordReset() {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const sendPasswordLink = (e) => {
    e.preventDefault();
    const formEl = document.forms.resetForm;
    const formData = new FormData(formEl);
    const email = formData.get("email");
    if ((email || "").length == 0)
      return notify("Please provide all fields to continue.", {
        type: "error",
      });
    setLoading(true);
    let url = app.api + "users/reset_password/";
    axios
      .post(url, { email })
      .then((re) => {
        notify("Please check your email box.", { type: "success" });
        formEl.reset();
      })
      .catch((error) => {
        notify("Some error occured.", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <section className="hero-section full-screen gray-light-bg">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
              <div
                className="bg-cover vh-100 ml-n3 background-img"
                style={{ background: "url(assets/img/hero-bg-3.jpg)" }}
              >
                <div className="position-absolute login-signup-content">
                  <div className="position-relative text-white col-md-12 col-lg-7">
                    <h2 className="text-white">
                      Forgot Password ? <br /> Don't Worry You Can Reset
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6">
              <div className="login-signup-wrap px-4 px-lg-5 my-5">
                <h1 className="text-center mb-1">Password Reset</h1>

                <p className="text-muted text-center mb-5">
                  Enter your email to get a password reset link.
                </p>

                <form
                  className="login-signup-form"
                  onSubmit={sendPasswordLink}
                  id="resetForm"
                >
                  <div className="form-group">
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-email color-primary"></span>
                      </div>
                      <input
                        required
                        type="email"
                        className="form-control"
                        placeholder="name@address.com"
                        name="email"
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    type="submit"
                    style={{ height: 50 }}
                    disabled={loading}
                  >
                    {loading ? (
                      <div class="spinner-grow"></div>
                    ) : (
                      "Reset Password"
                    )}
                  </button>

                  <div className="text-center">
                    <small className="text-muted text-center">
                      Remember your password? <Link to="/login">Log in</Link>.
                    </small>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
