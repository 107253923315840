import React from "react";
import TakeTest from "./TakeTest";
import PsychologyIcon from "@mui/icons-material/Psychology";

const TakeTestWrapper = () => {
  return <TakeTest mainStyle="ktsa" />;
};
export default {
  list: TakeTestWrapper,
  name: "ktsa",
  icon: PsychologyIcon,
  label: "Kinetic Thinking",
};
