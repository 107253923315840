import React from "react";
import TakeTest from "./TakeTest";
import TimelineIcon from "@mui/icons-material/Timeline";

const TakeTestWrapper = () => {
  return <TakeTest mainStyle="kmsa" />;
};
export default {
  list: TakeTestWrapper,
  name: "kmsa",
  icon: TimelineIcon,
  label: "Kinetic Managing",
};
