// in src/MyLayout.js
import * as React from "react";
import { forwardRef } from "react";
import { UserMenu, useLogout } from "react-admin";
import { MenuItem } from "@mui/material";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import { Lock as LockIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => logout();
  return (
    <MenuItem onClick={handleClick} ref={ref} {...props}>
      <ExitIcon sx={{ marginRight: 2 }} /> {"  "}Logout
    </MenuItem>
  );
});
const ManagePassword = forwardRef((props, ref) => {
  const navigate = useNavigate();
  return (
    <MenuItem
      onClick={() => navigate("/change-user-password")}
      ref={ref}
      {...props}
    >
      <LockIcon sx={{ marginRight: 2 }} /> {"  "}Manage Password
    </MenuItem>
  );
});
const ParentUserMenu = (props) => (
  <UserMenu>
    <ManagePassword {...props} />
    <MyLogoutButton />
  </UserMenu>
);
export default ParentUserMenu;
