import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import logo from "./logo.png";
import UserMenu from "./UserMenu";
import { app } from "../constants";
import { HomeRounded } from "@mui/icons-material";

const CustomAppBar = (props) => {
  const current_route = window.location.hash.replace("#/", "").split("/");
  const my_courses = current_route.includes("my_courses");
  return (
    <AppBar {...props} elevation={1} userMenu={<UserMenu {...props} />}>
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      ></Typography>
      <a
        style={{
          marginTop: 2,
        }}
        href={my_courses ? app.admin_site : app.public_site}
        {...(!my_courses && { target: "_blank" })}
      >
        {my_courses ? (
          <HomeRounded fontSize="large" sx={{ color: "white" }} />
        ) : (
          <img
            src={logo}
            style={{
              width: 50,
            }}
          />
        )}
      </a>
      <span
        style={{
          flex: 1,
        }}
      />
    </AppBar>
  );
};

export default CustomAppBar;
