import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  EditButton,
  ImageInput,
  ImageField,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import LessonIcon from "@mui/icons-material/LibraryBooks";
import { app } from "../constants";
import DuplicateButton from "../components/DuplicateButton";
import { refactorImages } from "../utils";

const transformData = async (_data) => {
  let data = { ..._data };
  await refactorImages(data, "image", true);
  if ((data?.image || []).length > 0)
    data.image = data.image[data.image.length - 1];
  return data;
};
const LessonList = (props) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="title" />
        <TextField source="video_link" label="Vimeo Video Id" emptyText="--" />
        <EditButton />
        <DuplicateButton model={"lessons"} />
      </Datagrid>
    </List>
  );
};
const LessonForm = (props) => {
  return (
    <SimpleForm
      {...props}
      validate={(values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
          if (values[key] != undefined) {
            if (
              !values[key] ||
              (typeof values[key] === "object" &&
                Object.keys(values[key]).length === 0)
            ) {
              errors[key] = `Please provide ${key} to continue.`;
            }
          }
        });
        try {
          delete errors.video_link;
        } catch {}
        return errors;
      }}
    >
      <TextInput source="title" {...app.inputAttrs} />
      <TextInput
        source="video_link"
        {...app.inputAttrs}
        label="Vimeo Video Id"
        validate={[]}
      />
      <ImageInput
        multiple
        source="image"
        label="Video Thumbnail"
        accept={["image/*"]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <RichTextInput
        source={"description"}
        {...app.inputAttrs}
        label={"Description"}
      />
      <TextInput
        source="short_summary"
        {...app.inputAttrs}
        label="Short Summary"
        validate={[]}
      />
    </SimpleForm>
  );
};
const LessonEdit = (props) => (
  <Edit {...props} transform={transformData}>
    <LessonForm {...props} />
  </Edit>
);
const LessonCreate = (props) => (
  <Create {...props} transform={transformData}>
    <LessonForm {...props} />
  </Create>
);
export default {
  list: LessonList,
  create: LessonCreate,
  edit: LessonEdit,
  name: "lessons",
  icon: LessonIcon,
  label: "Lessons",
};
