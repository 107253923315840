import { CircularProgress } from "@mui/material";
import { app } from "../constants";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

const Chart = ({ yValue, xValue, style }) => {
  const { slug } = style;
  const height = 300;
  const space = 20;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [matrix, setMatrix] = useState({
    ktsa: {
      top: {
        left: "Incremental",
        right: "Breakaway",
      },
      bottom: {
        left: "Focused",
        right: "Playful",
      },
      left: "Uncertainty",
      right: "Possibility",
    },
    kmsa: {
      top: {
        left: "Inquistive",
        right: "Venturing",
      },
      bottom: {
        left: "Efficient",
        right: "Supportive",
      },
      left: "Process",
      right: "Performance",
    },
    klsa: {
      top: {
        left: "Challenger",
        right: "Transformer",
      },
      bottom: {
        left: "Troubleshooter",
        right: "Co-creator",
      },
      left: "Time",
      right: "Ecosystem",
    },
  });
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await dataProvider.getList("report_contents", {
        pagination: { page: 1, perPage: 1000 },
        sort: {},
        filter: {
          style_id: style.id,
        },
      });
      if (data.length > 0) {
        let report_content = data[0].content;
        let _matrix = { ...matrix };
        _matrix[slug].top.left =
          report_content?.benchmark_top_left || _matrix[slug].top.left;
        _matrix[slug].top.right =
          report_content?.benchmark_top_right || _matrix[slug].top.right;
        _matrix[slug].bottom.left =
          report_content?.benchmark_bottom_left || _matrix[slug].bottom.left;
        _matrix[slug].bottom.right =
          report_content?.benchmark_bottom_right || _matrix[slug].bottom.right;
        _matrix[slug].left = report_content?.benchmark_1 || _matrix[slug].left;
        _matrix[slug].right =
          report_content?.benchmark_2 || _matrix[slug].right;
        setMatrix(_matrix);
      }
      setLoading(false);
    })();
  }, []);
  if (!["ktsa", "kmsa", "klsa"].includes(slug)) return <></>;
  if (loading)
    return (
      <div className="mt-3">
        <CircularProgress />
      </div>
    );
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-2"></div>
        <div className="col-12 col-sm-8 d-flex justify-content-center mt-3">
          <div
            style={{
              height,
              width: height,
              position: "relative",
            }}
            className="border rounded"
          >
            <div
              style={{
                width: 1,
                backgroundColor: "rgba(0,0,0,0.3)",
                height: height - space * 2,
                top: space,
                position: "absolute",
                left: height / 2,
              }}
            ></div>
            <div
              style={{
                width: 1,
                backgroundColor: "rgba(0,0,0,0.3)",
                height: height - space * 2,
                top: space,
                position: "absolute",
                left: height / 2,
                transform: "rotate(90deg)",
              }}
            ></div>
            <div className="d-flex justify-content-between p-1">
              <div
                className="px-2 border rounded"
                style={{
                  fontSize: 13,
                }}
              >
                {matrix[slug].top.left}
              </div>
              <div
                className="px-2 border rounded"
                style={{
                  fontSize: 13,
                }}
              >
                {matrix[slug].top.right}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}
            >
              <div className="d-flex justify-content-between p-1">
                <div
                  className="px-2 border rounded"
                  style={{
                    fontSize: 13,
                  }}
                >
                  {matrix[slug].bottom.left}
                </div>
                <div
                  className="px-2 border rounded"
                  style={{
                    fontSize: 13,
                  }}
                >
                  {matrix[slug].bottom.right}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: height - space - 4,
                width: "100%",
              }}
            >
              <div
                style={{
                  opacity: 0.5,
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                {matrix[slug].right}
              </div>
            </div>
            <div
              style={{
                transform: "rotate(-90deg)",
                position: "absolute",
                top: height / 2 - space + 8,
                left: -height / 2 + 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  opacity: 0.5,
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                {matrix[slug].left}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                height: 20,
                width: 20,
                borderRadius: 20,
                backgroundColor: app.colorOne,
                top: `calc(50% - 10px - ${yValue * 12}px)`,
                left: `calc(50% - 10px + ${xValue * 12}px)`,
              }}
            ></div>
          </div>
        </div>
        <div className="col-12 col-sm-2"></div>
      </div>
    </div>
  );
};
export default Chart;
