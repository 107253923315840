import { defaultTheme } from "react-admin";
import { required } from "react-admin";
const __DEV__ = process.env.NODE_ENV == "development";
export const app = {
  colorOne: "#002c5f",
  colorTwo: "#2EA3F2",
  lightColor: "rgba(103, 48, 227, 0.1)",
  inputAttrs: { fullWidth: true, variant: "outlined", validate: [required()] },
  filterAttrs: { fullWidth: true, variant: "outlined" },
  __DEV__,
  api: __DEV__
    ? "http://127.0.0.1:8000/api/v1/"
    : "https://kineticthinking-server-ec9576c00f06.herokuapp.com/api/v1/",
  public_site: __DEV__
    ? "http://localhost:3000"
    : "https://www.mykineticstyle.com",
  admin_site: __DEV__
    ? "http://localhost:3001/#/"
    : "https://dashboard.mykineticstyle.com/#/",
};
export const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: app.colorOne,
    },
    secondary: {
      main: app.colorOne,
      light: app.colorTwo,
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
    type: "light",
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "5px solid #0000000a",
      },
      active: {
        borderLeft: `5px solid ${app.colorOne}`,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: app.colorOne,
        boxShadow: "none",
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        marginRight: 10,
        borderBottom: `10px solid ${app.colorOne}`,
        paddingRight: 10,
        backgroundColor: "rgba(0, 0, 0, 1)",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: "none",
      },
    },
  },
};

export const passwordValidator = (password, extra = "") => {
  if (!password || password.length <= 0)
    return extra + "Password cannot be empty.";
  return "";
};
