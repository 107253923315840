import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
  EditButton,
  Create,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceField,
  ImageInput,
  ImageField,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import ReportContentIcon from "@mui/icons-material/Calculate";
import { app } from "../constants";
import DuplicateButton from "../components/DuplicateButton";
import { refactorImages } from "../utils";

const ReportContentList = (props) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="style" reference="styles">
          <TextField source="title" />
        </ReferenceField>
        <TextField
          source="content.style_type"
          label="Style Type"
          emptyText="--"
        />
        <EditButton />
        <DuplicateButton model="report_contents" />
      </Datagrid>
    </List>
  );
};
const transformData = async (_data) => {
  let data = { ..._data };
  await refactorImages(data, "style_header");
  await refactorImages(data, "right_benchmark_images");
  await refactorImages(data, "matrix_images");
  for (let i = 1; i <= 9; i++) {
    await refactorImages(data, `thinking_style.condition_${i}.images`);
    if (i <= 4)
      await refactorImages(data, `things_to_consider.condition_${i}.image`);
  }
  return data;
};
const ReportContentForm = (props) => {
  const textInputs = [
    { source: "content.style_type", label: "Style Type" },
    { source: "content.report_introduction", label: "Report Introduction" },
    {
      source: "content.style_type_introduction",
      label: "Style Type Introduction",
    },
    {
      source: "content.style_dimension_1_title",
      label: "Style Dimension 1 Title",
    },
    {
      source: "content.style_dimension_1_description",
      label: "Style Dimension 1 Description",
    },
    {
      source: "content.style_dimension_1_left",
      label: "Style Dimension 1 Left",
    },
    {
      source: "content.style_dimension_1_left_description",
      label: "Style Dimension 1 Left Description",
    },
    {
      source: "content.style_dimension_1_right",
      label: "Style Dimension 1 Right",
    },
    {
      source: "content.style_dimension_1_right_description",
      label: "Style Dimension 1 Right Description",
    },
    {
      source: "content.style_dimension_2_title",
      label: "Style Dimension 2 Title",
    },
    {
      source: "content.style_dimension_2_description",
      label: "Style Dimension 2 Description",
    },
    {
      source: "content.style_dimension_2_left",
      label: "Style Dimension 2 Left",
    },
    {
      source: "content.style_dimension_2_left_description",
      label: "Style Dimension 2 Left Description",
    },
    {
      source: "content.style_dimension_2_right",
      label: "Style Dimension 2 Right",
    },
    {
      source: "content.style_dimension_2_right_description",
      label: "Style Dimension 2 Right Description",
    },
    {
      source: "content.radar_dimension_1_left",
      label: "Radar Dimension 1 Left",
    },
    {
      source: "content.radar_dimension_1_right",
      label: "Radar Dimension 1 Right",
    },
    {
      source: "content.radar_dimension_2_left",
      label: "Radar Dimension 2 Left",
    },
    {
      source: "content.radar_dimension_2_right",
      label: "Radar Dimension 2 Right",
    },

    { source: "content.x_left_average", label: "X Left Average" },
    { source: "content.x_right_average", label: "X Right Average" },
    { source: "content.y_left_average", label: "Y Left Average" },
    { source: "content.y_right_average", label: "Y Right Average" },
    { source: "content.x_reference_1", label: "X Reference 1" },
    { source: "content.x_reference_2", label: "X Reference 2" },
    { source: "content.y_reference_1", label: "Y Reference 1" },
    { source: "content.y_reference_2", label: "Y Reference 2" },
    {
      source: "content.right_benchmark_images",
      label: "Right Benchmark Images (Required 4 Images)",
      image: true,
    },
    { source: "content.benchmark_1", label: "Benchmark 1" },
    { source: "content.benchmark_2", label: "Benchmark 2" },
    { source: "content.benchmark_top_left", label: "Benchmark Top Left Text" },
    {
      source: "content.benchmark_top_right",
      label: "Benchmark Top Right Text",
    },
    {
      source: "content.benchmark_bottom_left",
      label: "Benchmark Bottom Left Text",
    },
    {
      source: "content.benchmark_bottom_right",
      label: "Benchmark Bottom Right Text",
    },
    { source: "content.style_1_description", label: "Style 1 Description" },
    { source: "content.style_2_description", label: "Style 2 Description" },
    { source: "content.style_3_description", label: "Style 3 Description" },
    { source: "content.style_4_description", label: "Style 4 Description" },
    {
      source: "content.matrix_images",
      label: "Style Type Matrix Images (Required 4 Images)",
      image: true,
    },
  ];
  const thinkingStyleInputs = [
    {
      source: "content.thinking_style.condition_1",
      label: "X-Score > 0 and Y-Score > 0",
    },
    {
      source: "content.thinking_style.condition_2",
      label: " X-Score < 0 and Y-Score > 0",
    },
    {
      source: "content.thinking_style.condition_3",
      label: "X-Score < 0 and Y-Score < 0",
    },
    {
      source: "content.thinking_style.condition_4",
      label: "X-Score > 0 and Y-Score < 0",
    },
    {
      source: "content.thinking_style.condition_5",
      label: "X-Score < 0 and Y-Score == 0",
    },
    {
      source: "content.thinking_style.condition_6",
      label: "X-Score == 0 and Y-Score > 0",
    },
    {
      source: "content.thinking_style.condition_7",
      label: "X-Score > 0 and Y-Score == 0",
    },
    {
      source: "content.thinking_style.condition_8",
      label: "X-Score == 0 and Y-Score < 0",
    },
    {
      source: "content.thinking_style.condition_9",
      label: "X-Score == 0 and Y-Score == 0",
    },
  ];
  const levelInputs = [
    {
      source: "content.lever_style.condition_1",
      label: "Left Side: X-Score < 0",
    },
    {
      source: "content.lever_style.condition_2",
      label: "Left Side: X-Score > 0",
    },
    {
      source: "content.lever_style.condition_3",
      label: "Left Side: X-Score == 0",
    },
    {
      source: "content.lever_style.condition_4",
      label: "Right Side: Y-Score < 0",
    },
    {
      source: "content.lever_style.condition_5",
      label: "Right Side: Y-Score > 0",
    },
    {
      source: "content.lever_style.condition_6",
      label: "Right Side: Y-Score == 0",
    },
  ];
  const moveStyleInputs = [
    {
      source: "content.things_to_consider.condition_1",
      label: "X-Score < 0",
    },
    {
      source: "content.things_to_consider.condition_2",
      label: "X-Score > 0",
    },
    {
      source: "content.things_to_consider.condition_3",
      label: "Y-Score < 0",
    },
    {
      source: "content.things_to_consider.condition_4",
      label: "Y-Score > 0",
    },
    {
      source: "content.things_to_consider.condition_5",
      label: "X-Score == 0",
    },
    {
      source: "content.things_to_consider.condition_6",
      label: "Y-Score == 0",
    },
  ];
  return (
    <SimpleForm {...props}>
      <ReferenceInput source="style" reference="styles">
        <SelectInput optionText="title" {...app.inputAttrs} />
      </ReferenceInput>
      <ImageInput
        multiple
        source="content.style_header"
        label="Style Header Image"
        accept={["image/*"]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      {textInputs.map((input) =>
        input.image ? (
          <ImageInput
            multiple
            source={input.source}
            label={input.label}
            accept={["image/*"]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        ) : (
          <TextInput
            source={input.source}
            label={input.label}
            {...{ fullWidth: true, variant: "outlined" }}
          />
        )
      )}
      <strong>
        <p>Style Result Summary</p>
      </strong>
      {thinkingStyleInputs.map((input) => (
        <>
          <p
            style={{
              marginBottom: 0,
              fontSize: "0.8rem",
              color: "rgba(0, 0, 0, 0.7)",
              fontWeight: 600,
            }}
          >
            {input.label}
          </p>
          <TextInput
            source={input.source + ".name"}
            label={""}
            {...{ fullWidth: true, variant: "outlined" }}
            helperText=""
            placeholder="Name"
          />
          <RichTextInput
            helperText=""
            source={input.source + ".description"}
            {...{ fullWidth: true, variant: "outlined" }}
            label={"Description"}
            toolbar={() => <></>}
          />
          <ImageInput
            multiple
            source={input.source + ".images"}
            label="Style Images (Required 4 Images)"
            accept={["image/*"]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </>
      ))}
      <strong>
        <p>Style Dimensions Summary</p>
      </strong>
      {levelInputs.map((input) => (
        <TextInput
          source={input.source}
          label={input.label}
          {...{ fullWidth: true, variant: "outlined" }}
          helperText=""
          placeholder="Name"
        />
      ))}
      <strong>
        <p>Style Result Moves</p>
      </strong>
      {moveStyleInputs.map((input) => (
        <>
          <p
            style={{
              marginBottom: 0,
              fontSize: "0.8rem",
              color: "rgba(0, 0, 0, 0.7)",
              fontWeight: 600,
            }}
          >
            {input.label}
          </p>
          <TextInput
            source={input.source + ".name"}
            label={"Name"}
            {...{ fullWidth: true, variant: "outlined" }}
            helperText=""
          />
          <TextInput
            source={input.source + ".description"}
            label={"Description"}
            {...{ fullWidth: true, variant: "outlined" }}
            helperText=""
          />
          <ImageInput
            multiple
            source={input.source + ".image"}
            label="Style Result Moves Image (Required 1 Image)"
            accept={["image/*"]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </>
      ))}
    </SimpleForm>
  );
};
const ReportContentEdit = (props) => (
  <Edit
    {...props}
    transform={transformData}
    {...(app.__DEV__ && { mutationMode: "optimistic" })}
  >
    <ReportContentForm {...props} />
    {}
  </Edit>
);
const ReportContentCreate = (props) => (
  <Create {...props} transform={transformData}>
    <ReportContentForm {...props} />
  </Create>
);
export default {
  list: ReportContentList,
  edit: ReportContentEdit,
  create: ReportContentCreate,
  name: "report_contents",
  icon: ReportContentIcon,
  label: "Report Content",
};
