import { Link, useLocation, useNavigate } from "react-router-dom";

import React, { useEffect } from "react";
import { useState } from "react";
import { useLogin, useNotify, useCheckAuth } from "react-admin";

export default function LoginTwo() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formEl = document.forms.loginForm;
    const formData = new FormData(formEl);
    const email = formData.get("email");
    const password = formData.get("password");
    if (!email || !password)
      return notify("Please provide all values.", { type: "error" });
    setLoading(true);
    login({ email, password })
      .catch(() => notify("Invalid email or password", { type: "error" }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        navigate("/");
      })
      .catch(() => {});
  }, [checkAuth, navigate]);

  return (
    <>
      <section className="hero-section full-screen gray-light-bg">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
              <div
                className="bg-cover vh-100 ml-n3 background-img"
                style={{
                  background: "url(assets/img/hero-img.jpeg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="position-absolute login-signup-content">
                  <div className="position-relative text-white col-md-12 col-lg-7">
                    <h2 className="text-white">Welcome Back !</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
              <div className="login-signup-wrap px-4 px-lg-5 my-5">
                <h1 className="text-center mb-1">Sign In</h1>
                <p className="text-center mb-5">
                  Free access to our dashboard.
                </p>
                <form
                  className="login-signup-form"
                  onSubmit={handleSubmit}
                  id="loginForm"
                >
                  <div className="form-group">
                    <label className="pb-1">Email Address</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-email color-primary"></span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="name@yourdomain.com"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label className="pb-1">Password</label>
                      </div>
                    </div>
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-lock color-primary"></span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your password"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="text-left">
                    <small className="text-muted text-center">
                      Forgot Password ?{" "}
                      <Link to="/reset-password">Reset Password</Link>.
                    </small>
                  </div>
                  <div className="form-check d-flex align-items-center text-center">
                    <label className="form-check-label small"></label>
                  </div>
                  <button
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    type="submit"
                    style={{ height: 50 }}
                    disabled={loading}
                  >
                    {loading ? <div class="spinner-grow"></div> : "Sign in"}
                  </button>

                  <p className="text-center">
                    <small className="text-muted text-center">
                      Don't have an account yet?{" "}
                      <span
                        style={{
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/signup", { state: { ...location.state } });
                        }}
                      >
                        Sign up
                      </span>
                    </small>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
