import { app } from "./constants";
import axios from "axios";
import { getHeaders } from "./providers/dataProvider";

export const createUser = async (user) => {
  return await axios.post(app.api + "users/", {
    ...user,
    re_password: user.password,
  });
};

export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

export const round = (value) => {
  return Math.round(value * 100) / 100;
};

export const getReportUrl = (report_id) => {
  return `${app.api.replace(
    "/api/v1/",
    "/"
  )}get-report/?report_id=${report_id}.pdf`;
};

export const isAdmin = (role) => {
  return ["admin", "superadmin"].includes(role);
};

export const refactorImages = async (data, key, override = false) => {
  const getValueFromObj = (obj, keys) => {
    try {
      keys = keys.split(".");
      let value = obj;
      keys.forEach((key) => {
        value = value[key];
      });
      return value;
    } catch (error) {
      return null;
    }
  };
  const uploadFileOnServer = async (image) => {
    const defaultImage = "https://placehold.co/500/png";
    let rawFile = image.rawFile;
    const formData = new FormData();
    formData.append("image", rawFile);
    return fetch(app.api + "save-image/", {
      method: "POST",
      body: formData,
      ...getHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.uploaded) return data.download_url;
        else return defaultImage;
      })
      .catch((error) => defaultImage);
  };
  const images = getValueFromObj(override ? data : data.content, key) || [];
  const total_images = images.length;
  for (let i = 0; i < total_images; i++) {
    let image = images[i];
    if (image?.rawFile) {
      images[i].src = await uploadFileOnServer(image);
      delete images[i].rawFile;
    }
  }
};

export const extractPlainTextFromHTML = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const plainText = tempDiv.textContent;
  tempDiv.remove();
  return plainText;
};

export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getQueryParams = () => {
  let params = window.location.href.split("?");
  params = "?" + params[params.length - 1];
  return new URLSearchParams(params);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
