import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  EditButton,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  required,
} from "react-admin";
import SectionIcon from "@mui/icons-material/BackupTable";
import { app } from "../constants";
import DuplicateButton from "../components/DuplicateButton";
import { refactorImages } from "../utils";

const transformData = async (_data) => {
  let data = { ..._data };
  await refactorImages(data, "image", true);
  if ((data?.image || []).length > 0)
    data.image = data.image[data.image.length - 1];
  return data;
};
const SectionList = (props) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="title" />
        <ReferenceArrayField
          label="Lessons"
          reference="lessons"
          source="lessons"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton />
        <DuplicateButton model={"sections"} />
      </Datagrid>
    </List>
  );
};
const SectionForm = (props) => {
  return (
    <SimpleForm
      {...props}
      validate={(values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
          if (!values[key]) errors[key] = `Please provide ${key} to continue.`;
        });
        if ((values.lessons || []).length == 0) {
          errors.lessons = "Please add minimum one section to continue.";
          return errors;
        }
        if (values.lessons.length != [...new Set(values.lessons)].length) {
          errors.lessons = "Duplicate lessons found.";
        }
        return errors;
      }}
    >
      <TextInput source="title" {...app.inputAttrs} />
      <ImageInput
        multiple
        source="image"
        label="Section Image"
        accept={["image/*"]}
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="description" {...app.inputAttrs} />
      <ArrayInput source={"lessons"} {...app.inputAttrs}>
        <SimpleFormIterator inline fullWidth>
          <ReferenceInput reference="lessons">
            <SelectInput optionText="title" {...app.inputAttrs} />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
const SectionEdit = (props) => (
  <Edit {...props} transform={transformData}>
    <SectionForm {...props} />
  </Edit>
);
const SectionCreate = (props) => (
  <Create {...props} transform={transformData}>
    <SectionForm {...props} />
  </Create>
);
export default {
  list: SectionList,
  create: SectionCreate,
  edit: SectionEdit,
  name: "sections",
  icon: SectionIcon,
  label: "Sections",
};
