import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  UrlField,
} from "react-admin";
import CredentialIcon from "@mui/icons-material/CardMembership";
import { Card } from "@mui/material";

const NoCredentials = () => {
  return (
    <div className="mt-3 w-100">
      <Card
        sx={{
          padding: 2,
        }}
      >
        Sorry, no credentials found.
      </Card>
    </div>
  );
};
const CredentialList = (props) => {
  return (
    <List {...props} resource="courses/credentials" empty={<NoCredentials />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="user" />
        <TextField source="course" />
        <UrlField
          source="response.credential.url"
          label="Credential Link"
          target="_blank"
        />
      </Datagrid>
    </List>
  );
};

export default {
  list: CredentialList,
  name: "credentials",
  icon: CredentialIcon,
  label: "Credentials",
};
