import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  EditButton,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  useRecordContext,
  CheckboxGroupInput,
} from "react-admin";
import CourseIcon from "@mui/icons-material/School";
import { app } from "../constants";
import DuplicateButton from "../components/DuplicateButton";
import { refactorImages } from "../utils";

const transformData = async (_data) => {
  let data = { ..._data };
  await refactorImages(data, "image", true);
  if ((data?.image || []).length > 0)
    data.image = data.image[data.image.length - 1];
  if ((data?.has_credentials || []).length == 0) {
    try {
      data.credentials_group_name = null;
      data.credentials_group_id = null;
    } catch {}
  }
  return data;
};
const CourseList = (props) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="title" />
        <TextField source="description" />
        <EditButton />
        <DuplicateButton model={"courses"} />
      </Datagrid>
    </List>
  );
};
const CourseForm = (props) => {
  const record = useRecordContext();
  const [hasCredentials, setHasCredentials] = useState(
    record?.has_credentials || []
  );
  return (
    <SimpleForm
      {...props}
      validate={(values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
          if (!values[key] || [undefined, null].includes(values[key])) {
            if (key != "has_credentials")
              errors[key] = `Please provide ${key} to continue.`;
          }
        });
        if ((values?.has_credentials || []).length == 0) {
          try {
            delete errors.credentials_group_name;
            delete errors.credentials_group_id;
          } catch {}
        }
        if ((values.sections || []).length == 0) {
          errors.sections = "Please add minimum one section to continue.";
          return errors;
        }
        if (values.sections.length != [...new Set(values.sections)].length) {
          errors.sections = "Duplicate sections found.";
        }
        return errors;
      }}
    >
      <TextInput source="title" {...app.inputAttrs} />
      <ImageInput
        multiple
        source="image"
        label="Course Image"
        accept={["image/*"]}
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="description" {...app.inputAttrs} />
      <ArrayInput source={"sections"} {...app.inputAttrs}>
        <SimpleFormIterator inline fullWidth>
          <ReferenceInput reference="sections">
            <SelectInput optionText="title" {...app.inputAttrs} />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceArrayInput source="groups" reference="user_groups" fullWidth>
        <AutocompleteArrayInput
          optionText="title"
          {...app.inputAttrs}
          validate={[]}
        />
      </ReferenceArrayInput>
      <CheckboxGroupInput
        source="has_credentials"
        choices={[{ id: "has_credentials", name: "Has Credentials?" }]}
        label="Has Credentials?"
        value={hasCredentials}
        onChange={(event) => {
          setHasCredentials(event);
        }}
      />
      {hasCredentials.length > 0 && (
        <TextInput
          source="credentials_group_name"
          {...app.inputAttrs}
          label="Group Name"
        />
      )}
      {hasCredentials.length > 0 && (
        <NumberInput
          source="credentials_group_id"
          {...app.inputAttrs}
          label="Group Id"
        />
      )}
    </SimpleForm>
  );
};
const CourseEdit = (props) => (
  <Edit {...props} transform={transformData}>
    <CourseForm {...props} />
  </Edit>
);
const CourseCreate = (props) => (
  <Create {...props} transform={transformData}>
    <CourseForm {...props} />
  </Create>
);
export default {
  list: CourseList,
  create: CourseCreate,
  edit: CourseEdit,
  name: "courses",
  icon: CourseIcon,
  label: "Courses",
};
