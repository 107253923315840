import React from "react";
import TakeTest from "./TakeTest";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

const TakeTestWrapper = () => {
  return <TakeTest mainStyle="klsa" />;
};
export default {
  list: TakeTestWrapper,
  name: "klsa",
  icon: DoubleArrowIcon,
  label: "Kinetic Leading",
};
