import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useNotify, useCheckAuth, useLogin } from "react-admin";
import { createUser } from "../admin/utils";

export default function SignupTwo() {
  const notify = useNotify();
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  const login = useLogin();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formEl = document.forms.signupForm;
    const formData = new FormData(formEl);
    const accepted = formData.get("accepted");
    if (!accepted)
      return notify("Please accept terms to continue.", {
        type: "error",
      });
    const name = formData.get("name");
    const email = formData.get("email");
    const password = formData.get("password");
    if (!email || !password || !name)
      return notify("Please provide all values.", { type: "error" });
    setLoading(true);
    createUser({ name, email, password })
      .then((res) => {
        notify("User created successfully.", {
          type: "success",
        });
        login({ email, password });
      })
      .catch((error) => {
        let response = error.response.data;
        let email_errors = response?.email || [];
        let password_errors = response?.password || [];
        notify([...email_errors, ...password_errors].join("."), {
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        navigate("/");
      })
      .catch(() => {});
  }, [checkAuth, navigate]);

  return (
    <>
      <section className="hero-section full-screen gray-light-bg">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
              <div
                className="bg-cover vh-100 ml-n3 background-img"
                style={{ background: "url(assets/img/hero-bg-2.jpg)" }}
              >
                <div className="position-absolute login-signup-content">
                  <div className="position-relative text-white col-md-12 col-lg-7">
                    <h2 className="text-white">Create Your Account</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
              <div className="login-signup-wrap px-4 px-lg-5 my-5">
                <h1 className="text-center mb-1">Signup</h1>

                <p className="text-muted text-center mb-5">
                  Free access to our dashboard.
                </p>

                <form
                  className="login-signup-form"
                  onSubmit={handleSubmit}
                  id="signupForm"
                >
                  <div className="form-group">
                    <label className="pb-1">Your Name</label>

                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-user color-primary"></span>
                      </div>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="pb-1">Email Address</label>

                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-email color-primary"></span>
                      </div>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="name@address.com"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="pb-1">Password</label>

                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-lock color-primary"></span>
                      </div>
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Enter your password"
                      />
                    </div>
                  </div>

                  <div className="form-check d-flex align-items-center text-center">
                    <input
                      type="checkbox"
                      className="form-check-input mt-0 mr-3"
                      id="accepted"
                      name="accepted"
                    />
                    <label
                      className="form-check-label small"
                      htmlFor="accepted"
                    >
                      I accept the{" "}
                      <a
                        target="_blank"
                        href="https://www.mykineticstyle.com/privacy-policy"
                      >
                        terms of use
                      </a>
                    </label>
                  </div>

                  <button
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    type="submit"
                    style={{ height: 50 }}
                    disabled={loading}
                  >
                    {loading ? <div class="spinner-grow"></div> : "Sign up"}
                  </button>

                  <div className="text-center">
                    <small className="text-muted text-center">
                      Already have an account? <Link to="/login">Log in</Link>.
                    </small>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
