import * as React from "react";
import "./index.css";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import { QueryClient } from "react-query";

import layout from "./layout";

import { theme } from "./constants";

import users from "./users";
import user_groups from "./user_groups";
import questions from "./questions";
import reports from "./reports";
import styles from "./styles";
import contacts from "./contacts";
import report_contents from "./report_contents";
// Course Admin Routes
import lessons from "./lessons";
import sections from "./sections";
import courses from "./courses";
import credentials from "./credentials";
import completions from "./completions";
//
import ktsa from "./tests/ktsa";
import kmsa from "./tests/kmsa";
import klsa from "./tests/klsa";
//
import LoginTwo from "../components/LoginTwo";
import SignupTwo from "../components/SignupTwo";
import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import PasswordReset from "../components/PasswordReset";
import ChangePassword from "../components/ChangePassword";
import Password from "./password";
import { isAdmin } from "./utils";
// Course User Routes
import Courses from "./my_courses";
import Course from "./my_courses/Course";
import Sections from "./my_courses/Sections";
import Lessons from "./my_courses/Lessons";
import FinishCourse from "./my_courses/FinishCourse";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const AdminPanel = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      layout={layout}
      authProvider={authProvider}
      loginPage={LoginTwo}
      theme={theme}
      queryClient={queryClient}
    >
      {(permissions) => [
        <Resource {...reports} />,
        <Resource {...credentials} />,
        <Resource {...completions} />,
        ...(isAdmin(permissions?.role)
          ? [
              <Resource {...questions} />,
              <Resource {...users} />,
              <Resource {...user_groups} />,
              <Resource {...styles} />,
              <Resource {...contacts} />,
              <Resource {...report_contents} />,
              <Resource {...lessons} />,
              <Resource {...sections} />,
              <Resource {...courses} />,
            ]
          : [
              <Resource {...ktsa} />,
              <Resource {...kmsa} />,
              <Resource {...klsa} />,
            ]),
      ]}
      <CustomRoutes noLayout>
        <Route path="/signup" element={<SignupTwo />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/change-user-password" element={<Password />} />
      </CustomRoutes>
      {/* My Course Routes */}
      <CustomRoutes noLayout>
        <Route path="/my_courses" element={<Courses />} />
        <Route path="/my_courses/:course_id" element={<Course />} />
        <Route
          path="/my_courses/:course_id/section/:section_id"
          element={<Sections />}
        />
        <Route
          path="/my_courses/:course_id/section/:section_id/lesson/:lesson_id"
          element={<Lessons />}
        />
        <Route
          path="/my_courses/:course_id/finish"
          element={<FinishCourse />}
        />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminPanel;
