import React, { useEffect, useState } from "react";
import Template from "./components/Template";
import { useDataProvider, useNotify } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import CourseProgress from "./components/CourseProgress";

const Course = () => {
  const params = useParams();
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await dataProvider.getOne("courses", {
          id: params.course_id,
          query: {
            complete_fetch: "sections",
          },
        });
        setCourse(data);
        setLoading(false);
      } catch (error) {
        notify("Sorry, course not found", { type: "error" });
        navigate("/my_courses");
      }
    })();
  }, []);
  const Section = ({ id, image, title, description }) => {
    return (
      <div
        className="course-card highlight-on-hover row align-items-center"
        onClick={() => navigate("section/" + id)}
      >
        <div className="col-12 col-lg-4">
          <center>
            <div
              style={{
                height: 120,
                backgroundImage: `url('${image.src}')`,
              }}
              className="course-img"
            ></div>
          </center>
        </div>
        <div className="col-12 col-lg-8">
          <h5 className="mb-1">{title}</h5>
          <p className="mb-0">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Template
        header={{
          title: course?.title,
          description: course?.description,
        }}
        title={"Sections"}
        contentLoading={loading}
        headerLoading={loading}
      >
        {course?.sections && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Card>
                  {course.sections.map((section, index) => (
                    <Section key={index} {...section} />
                  ))}
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <CourseProgress course={course} />
              </Grid>
            </Grid>
          </>
        )}
      </Template>
    </>
  );
};

export default Course;
