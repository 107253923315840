import { CircularProgress, Container, Typography } from "@mui/material";
import React from "react";

const Header = ({ title, description, contentLoading }) => {
  return (
    <div
      style={{
        minHeight: 400,
        marginTop: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "url(assets/img/hero-img.jpeg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginBottom: 20,
        flexDirection: "column",
        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
        padding: "20px 0px",
      }}
    >
      {contentLoading ? (
        <CircularProgress style={{ color: "white" }} />
      ) : (
        <>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              textAlign: "center",
              fontFamily: '"Montserrat", sans-serif',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          <Container maxWidth="sm">
            <Typography
              component="p"
              align="center"
              fontSize={18}
              style={{ color: "white !important" }}
              color={"white"}
            >
              {description}
            </Typography>
          </Container>
        </>
      )}
    </div>
  );
};

export default Header;
