// in src/Menu.js
import * as React from "react";
import { Menu, MenuItemLink, usePermissions } from "react-admin";

import users from "../users";
import user_groups from "../user_groups";
import reports from "../reports";
import credentials from "../credentials";
import completions from "../completions";
import questions from "../questions";
import styles from "../styles";
import contacts from "../contacts";
import report_contents from "../report_contents";
//
import ktsa from "../tests/ktsa";
import kmsa from "../tests/kmsa";
import klsa from "../tests/klsa";
//
import lessons from "../lessons";
import sections from "../sections";
import courses from "../courses";
//
import { isAdmin } from "../utils";
import SubMenu from "./SubMenu";

const CustomMenu = (props) => {
  const { permissions } = usePermissions();
  const [state, setState] = React.useState({
    reports: true,
    users: true,
    courses: true,
    tests: true,
  });
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  return (
    <Menu {...props}>
      {isAdmin(permissions?.role) && (
        <>
          <SubMenu
            handleToggle={() => handleToggle("users")}
            isOpen={state.users}
            name="Manage Users"
            icon={<users.icon />}
          >
            <MenuItemLink
              to={users.name}
              primaryText={users.label}
              leftIcon={<users.icon />}
            />
            <MenuItemLink
              to={user_groups.name}
              primaryText={user_groups.label}
              leftIcon={<user_groups.icon />}
            />
            <MenuItemLink
              to={contacts.name}
              primaryText={contacts.label}
              leftIcon={<contacts.icon />}
            />
          </SubMenu>
          <MenuItemLink
            to={questions.name}
            primaryText={questions.label}
            leftIcon={<questions.icon />}
          />
          <MenuItemLink
            to={styles.name}
            primaryText={styles.label}
            leftIcon={<styles.icon />}
          />

          <SubMenu
            handleToggle={() => handleToggle("reports")}
            isOpen={state.reports}
            name="Manage Reports"
            icon={<reports.icon />}
          >
            <MenuItemLink
              to={reports.name}
              primaryText={reports.label}
              leftIcon={<reports.icon />}
            />
            <MenuItemLink
              to={report_contents.name}
              primaryText={report_contents.label}
              leftIcon={<report_contents.icon />}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("courses")}
            isOpen={state.courses}
            name="Manage Courses"
            icon={<courses.icon />}
          >
            <MenuItemLink
              to={lessons.name}
              primaryText={lessons.label}
              leftIcon={<lessons.icon />}
            />
            <MenuItemLink
              to={sections.name}
              primaryText={sections.label}
              leftIcon={<sections.icon />}
            />
            <MenuItemLink
              to={courses.name}
              primaryText={courses.label}
              leftIcon={<courses.icon />}
            />
            <MenuItemLink
              to={completions.name}
              primaryText={completions.label}
              leftIcon={<completions.icon />}
            />
          </SubMenu>
        </>
      )}
      {!isAdmin(permissions?.role) && (
        <>
          <SubMenu
            handleToggle={() => handleToggle("tests")}
            isOpen={state.tests}
            name="Take Assessment"
            icon={<ktsa.icon />}
          >
            <MenuItemLink
              to={ktsa.name}
              primaryText={ktsa.label}
              leftIcon={<ktsa.icon />}
            />
            <MenuItemLink
              to={kmsa.name}
              primaryText={kmsa.label}
              leftIcon={<kmsa.icon />}
            />
            <MenuItemLink
              to={klsa.name}
              primaryText={klsa.label}
              leftIcon={<klsa.icon />}
            />
          </SubMenu>
          <MenuItemLink
            to={reports.name}
            primaryText={reports.label}
            leftIcon={<reports.icon />}
          />
          <MenuItemLink
            to={"my_courses"}
            primaryText={"My Courses"}
            leftIcon={<courses.icon />}
          />
          <MenuItemLink
            to={completions.name}
            primaryText={"Course Completions"}
            leftIcon={<completions.icon />}
          />
        </>
      )}
      <MenuItemLink
        to={credentials.name}
        primaryText={credentials.label}
        leftIcon={<credentials.icon />}
      />
    </Menu>
  );
};
export default CustomMenu;
