import React, { useEffect, useState } from "react";
//
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
//
import { app } from "../constants";
import { shuffle, random } from "lodash";
import EndTest from "./EndTest";
import StartTest from "./StartTest";
import { Title, useCreate, useDataProvider, usePermissions } from "react-admin";
import { generateRandomString, getQueryParams, round } from "../utils";
//
const CustomSlider = styled(Slider)({
  height: 8,
  "& .MuiSlider-rail": {
    backgroundColor: app.colorOne,
  },
  "& .MuiSlider-track": {
    backgroundColor: "#A0AFC3",
    borderColor: app.lightColor,
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#FFF",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#FFF",
    },
  },
});
//
const TakeTest = ({ mainStyle }) => {
  const [question, setQuestion] = useState();
  const [questionMainIndex, setQuestionMainIndex] = useState({ 1: [], 2: [] });
  const [state, setState] = useState(0);
  const [values, setValues] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [currentStyle, setCurrentStyle] = useState({});
  const dataProvider = useDataProvider();
  const [report, setReport] = useState({});
  const { permissions } = usePermissions();
  //
  const getShuffleArray = (n, rangeEnd) => {
    const rangeStart = 0;
    const array = [];
    while (array.length < n) {
      const randomElement =
        Math.floor(Math.random() * (rangeEnd - rangeStart + 1)) + rangeStart;

      if (!array.includes(randomElement)) {
        array.push(randomElement);
      }
    }
    return array;
  };
  const swapOption = (options, key, n1, n2) => {
    let temp = options[n1].op[key];
    options[n1].op[key] = options[n2].op[key];
    options[n2].op[key] = temp;
  };
  const shuffleOptions = (options, key) => {
    let ops = getShuffleArray(4, options.length - 1);
    swapOption(options, key, ops[0], ops[1]);
    swapOption(options, key, ops[2], ops[3]);
  };
  const questionAndOptionsShuffle = (_question, key, mainIndexs) => {
    let options = shuffle(
      _question[`options_${key}`].map((op, index) => ({ index: index, op }))
    );
    // Shuffle right left options
    shuffleOptions(options, "right");
    shuffleOptions(options, "left");
    // Get indexes
    mainIndexs[key] = options.map((op) => {
      return { index: op.index, option_swap: random(0, 1) == 1 };
    });
    _question[`options_${key}`] = options.map((op) => op.op);
    let swaps = mainIndexs[key].map((index) => index.option_swap);
    for (let i = 0; i < swaps.length; i++) {
      const swap = swaps[i];
      if (swap) {
        let temp = _question[`options_${key}`][i].left;
        _question[`options_${key}`][i].left =
          _question[`options_${key}`][i].right;
        _question[`options_${key}`][i].right = temp;
      }
    }
  };
  const loadQuestions = async (style_id) => {
    const questions = (
      await dataProvider.getList("questions", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "created_at", order: "DESC" },
      })
    ).data;
    setLoading(true);
    try {
      let _question = questions.filter((i) => i.style == style_id);
      _question = _question[0];
      let mainIndexs = { ...questionMainIndex };
      questionAndOptionsShuffle(_question, "1", mainIndexs);
      questionAndOptionsShuffle(_question, "2", mainIndexs);
      setQuestionMainIndex(mainIndexs);
      setQuestion(_question);
    } catch (error) {
      setQuestion({});
    }
    setLoading(false);
    setState(0);
  };
  const getCurrentStyle = () => {
    if (!["ktsa", "kmsa", "klsa"].includes(mainStyle)) return "ktsa";
    return mainStyle;
  };
  const loadData = async () => {
    let style = getCurrentStyle();
    const styles = (
      await dataProvider.getList("styles", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "created_at", order: "DESC" },
      })
    ).data;
    try {
      style = styles.filter((st) => st.slug == style)[0];
    } catch (error) {}
    if (!style?.id) {
      setLoading(false);
      return;
    }
    setCurrentStyle(style);
    loadQuestions(style.id);
  };
  //
  useEffect(() => {
    loadData();
  }, []);
  //
  const QuestionSlider = ({ slider, setSlider }) => {
    return (
      <CustomSlider
        value={slider}
        step={10}
        marks
        min={0}
        max={100}
        onChange={(event) => {
          setSlider(event.target.value);
        }}
      />
    );
  };
  const SliderBar = ({ height, active, onPress }) => {
    return (
      <div
        style={{
          backgroundColor: "#C1D645",
          height,
          width: 25,
          borderRadius: "15px 15px 0px 0px",
          opacity: active ? 1 : 0.4,
          cursor: "pointer",
        }}
        onClick={onPress}
      ></div>
    );
  };
  const SliderBars = ({ sliderValue, setSlider }) => {
    return (
      <div
        style={{
          height: 120,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <SliderBar
          height={"100%"}
          active={sliderValue <= 0}
          onPress={() => setSlider(0)}
        />
        <SliderBar
          height={"80%"}
          active={sliderValue <= 10}
          onPress={() => setSlider(10)}
        />
        <SliderBar
          height={"60%"}
          active={sliderValue <= 20}
          onPress={() => setSlider(20)}
        />
        <SliderBar
          height={"40%"}
          active={sliderValue <= 30}
          onPress={() => setSlider(30)}
        />
        <SliderBar
          height={"20%"}
          active={sliderValue <= 40}
          onPress={() => setSlider(40)}
        />
        <SliderBar height={"0%"} onPress={() => setSlider(50)} />
        <SliderBar
          height={"20%"}
          active={sliderValue >= 60}
          onPress={() => setSlider(60)}
        />
        <SliderBar
          height={"40%"}
          active={sliderValue >= 70}
          onPress={() => setSlider(70)}
        />
        <SliderBar
          height={"60%"}
          active={sliderValue >= 80}
          onPress={() => setSlider(80)}
        />
        <SliderBar
          height={"80%"}
          active={sliderValue >= 90}
          onPress={() => setSlider(90)}
        />
        <SliderBar
          height={"100%"}
          active={sliderValue >= 100}
          onPress={() => setSlider(100)}
        />
      </div>
    );
  };
  const QuestionHandler = () => {
    const [answers, setAnswers] = useState([]);
    const [sliderValue, setSliderValue] = useState(50);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [optionIndex, setOptionIndex] = useState(0);
    const [create, { isLoading, error }] = useCreate();
    //
    const maxOptions = 6;
    //
    useEffect(() => {
      if (answers.length == 12) {
        // Finish Game
        finishQuestion();
      }
    }, [answers]);
    const sumValues = (question_no) => {
      return answers
        .filter((i) => i.question_no == question_no)
        .reduce((partialSum, a) => partialSum + a.value, 0);
    };
    const finishQuestion = async () => {
      // Normalize States
      setQuestionIndex(0);
      setOptionIndex(0);
      setSliderValue(50);
      // Do Actions
      setLoading(true);
      let xQuestion = sumValues(0) / 3;
      let yQuestion = sumValues(1) / 3;
      setValues({ x: xQuestion, y: yQuestion });
      let _report = {
        report_id: generateRandomString(32),
        user_id: permissions.user_id,
        xScore: round(xQuestion),
        yScore: round(yQuestion),
        link_group: getQueryParams().get("group") || "",
        style_id: currentStyle.id,
        shuffled_question: question,
      };
      setReport(_report);
      // Generate Report
      create("reports", { data: _report });
      setLoading(false);
      setState(2);
    };
    const recordAnswer = (option_index, question_no) => {
      let value = (sliderValue - 50) / 10;
      let mainIndex = questionMainIndex[question_no + 1][option_index];
      if (mainIndex.option_swap) value = -value;
      let _answer = {
        option_index,
        question_no,
        value,
      };
      setAnswers([...answers, _answer]);
    };
    const nextOption = () => {
      let _index = optionIndex;
      if ((optionIndex + 1) % maxOptions == 0 && optionIndex > 1) {
        let qIndex = questionIndex + 1;
        if (questionIndex >= 1) {
          recordAnswer(optionIndex, questionIndex);
          return;
        }
        setQuestionIndex(qIndex);
        recordAnswer(optionIndex, questionIndex);
        setOptionIndex(0);
      } else {
        recordAnswer(_index, questionIndex);
        setOptionIndex(_index + 1);
      }
      setSliderValue(50);
    };
    return (
      <div style={{ flex: 1 }}>
        <CardContent>
          <Typography
            align="left"
            sx={{ fontWeight: "bold", mb: 2 }}
            color={"primary"}
          >
            {`Scenario ${questionIndex + 1}: Question ${
              optionIndex + 1
            } of ${maxOptions}`}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }} align="left">
            {question[`question_${questionIndex + 1}`]}
          </Typography>
          <div
            style={{
              display: "flex",
              marginBottom: 20,
            }}
          >
            <Typography
              variant="h6"
              align="left"
              sx={{ flex: 1, fontWeight: "bold" }}
            >
              {question[`options_${questionIndex + 1}`][optionIndex].left}
            </Typography>
            <Typography
              variant="h6"
              align="right"
              sx={{ flex: 1, fontWeight: "bold" }}
            >
              {question[`options_${questionIndex + 1}`][optionIndex].right}
            </Typography>
          </div>
          <SliderBars sliderValue={sliderValue} setSlider={setSliderValue} />
          <QuestionSlider slider={sliderValue} setSlider={setSliderValue} />
          <Button
            variant="contained"
            onClick={nextOption}
            sx={{
              mt: 2,
              color: "#FFF",
            }}
            disabled={sliderValue == 50}
          >
            Next
          </Button>
        </CardContent>
      </div>
    );
  };
  return (
    <center>
      <div
        style={{
          maxWidth: 767,
        }}
      >
        <Title title={currentStyle?.title || "Take Test"} />
        <Card sx={{ mt: 2 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                height: 200,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {state == 0 && (
                <StartTest
                  question={question}
                  setState={setState}
                  style={currentStyle}
                />
              )}
              {state == 1 && <QuestionHandler />}
              {state == 2 && (
                <EndTest
                  xValue={values.x}
                  yValue={values.y}
                  report={report}
                  style={currentStyle}
                />
              )}
            </>
          )}
        </Card>
      </div>
    </center>
  );
};

export default TakeTest;
