import React, { useEffect } from "react";
import CustomAppBar from "../../layout/AppBar";
import { useCheckAuth } from "react-admin";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Header from "./Header";
import Loader from "../../components/Loader";
import { app } from "../../constants";
import logo from "./logo.png";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const Template = ({
  children,
  header,
  title,
  contentLoading,
  headerLoading,
}) => {
  const window_hash = window.location.hash;
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {})
      .catch(() => {
        navigate("/login");
      });
  }, [checkAuth, navigate]);
  return (
    <div
      style={{
        backgroundColor: "#ECF0F1",
      }}
    >
      <CustomAppBar
        sx={{
          "& .RaLoadingIndicator-loadedIcon": {
            display: "none",
          },
          "& .RaAppBar-menuButton": {
            display: "none",
          },
        }}
      />
      {header ? (
        <Header {...header} contentLoading={headerLoading} />
      ) : (
        <div style={{ marginTop: 50 }}></div>
      )}
      {contentLoading ? (
        <div style={{ paddingTop: 50 }}>
          <Loader />
        </div>
      ) : (
        <>
          <Container fixed>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{
                ...(!header && {
                  paddingTop: 30,
                }),
              }}
            >
              {window_hash != "#/my_courses" && (
                <Link
                  underline="hover"
                  href="/#/my_courses"
                  color="text.primary"
                >
                  My Courses
                </Link>
              )}
              {window_hash.search("section") != -1 && (
                <Link
                  underline="hover"
                  href={window.location.href
                    .replace(/\/lesson\/\d+$/, "")
                    .replace(/\/my_courses\/(\d+)\/.*$/, "/my_courses/$1")}
                  color="text.primary"
                >
                  Sections
                </Link>
              )}
              {window_hash.search("lesson") != -1 && (
                <Link
                  underline="hover"
                  href={window.location.href.replace(/\/lesson\/\d+$/, "")}
                  color="text.primary"
                >
                  Lessons
                </Link>
              )}
            </Breadcrumbs>
            <h2
              style={{
                textAlign: "center",
                margin: "30px",
              }}
            >
              {title}
            </h2>
            {children}
          </Container>
        </>
      )}
      <footer
        style={{
          height: 200,
          borderTop: "1px solid rgba(0,0,0,0.1)",
          marginTop: 100,
          backgroundColor: app.colorOne,
          paddingTop: 20,
        }}
      >
        <Container fixed>
          <img
            src={logo}
            style={{
              width: 50,
            }}
          />
        </Container>
      </footer>
    </div>
  );
};

export default Template;
