import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@mui/material";
import Template from "./components/Template";
import FinishCourseForms from "./components/FinishCourseForms";

const FinishCourse = () => {
  const params = useParams();
  const dataProvider = useDataProvider();
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notify = useNotify();
  //
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let _course = await dataProvider.getOne("courses", {
          id: params.course_id,
        });
        _course = _course.data;
        if (!_course) throw null;
        if (_course.has_credentials.length == 0) throw null;
        if (_course.user_lessons.length != _course.all_lessons.length)
          throw null;
        setCourse(_course);
        setLoading(false);
      } catch (error) {
        notify("Sorry, something happened.", { type: "error" });
        navigate("/my_courses");
      }
    })();
  }, []);
  //
  return (
    <Template
      header={{
        title: course?.title,
      }}
      title={"🎉 Congratulations on your completion!"}
      contentLoading={loading}
      headerLoading={loading}
    >
      <Card>
        <FinishCourseForms course_id={course.id} />
      </Card>
    </Template>
  );
};

export default FinishCourse;
