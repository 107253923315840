import React, { useState } from "react";
import { Button, useRecordContext, useRefresh } from "react-admin";
import axios from "axios";
import CopyAll from "@mui/icons-material/CopyAll";
import { app } from "../constants";

const DuplicateButton = ({ model }) => {
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();
  const refresh = useRefresh();
  const makeActiveTrade = async () => {
    const url = `${app.api.replace(
      "/api/v1/",
      `/duplicate-data?id=${record.id}&model=${model}`
    )}`;
    setLoading(true);
    Promise.resolve(axios.get(url))
      .then(() => {
        refresh();
      })
      .catch(() => {
        refresh();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Button
      onClick={makeActiveTrade}
      startIcon={<CopyAll />}
      label={loading ? "Duplicating..." : "Duplicate This"}
    />
  );
};
export default DuplicateButton;
