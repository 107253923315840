import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNotify } from "react-admin";
import { app } from "../admin/constants";
import axios from "axios";

export default function ChangePassword() {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const changePassword = (e) => {
    e.preventDefault();
    let params = window.location.href.split("?");
    params = "?" + params[params.length - 1];
    const searchParams = new URLSearchParams(params);
    let uid = searchParams.get("uid") || "";
    let token = searchParams.get("token") || "";
    if (token.length == 0 || uid.length == 0)
      return notify("Please provide uid and token to continue.", {
        type: "error",
      });
    const formEl = document.forms.resetForm;
    const formData = new FormData(formEl);
    const password = formData.get("password");
    const re_password = formData.get("re_password");
    if ((password || "").length == 0 || (re_password || "").length == 0)
      return notify("Please provide all fields to continue.", {
        type: "error",
      });
    if (re_password != password)
      return notify("Sorry, Both passwords are not same.", {
        type: "error",
      });
    setLoading(true);
    let url = app.api + "users/reset_password_confirm/";
    axios
      .post(url, { new_password: password.toString(), uid, token })
      .then((re) => {
        notify("Password changed successfully.", { type: "success" });
        window.location = "#/login";
      })
      .catch((err) => {
        console.log(err);
        let errors = [];
        try {
          errors = err.response.data.new_password;
        } catch (e) {
          errors = [];
        }
        notify([...errors].join(""), { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <section className="hero-section full-screen gray-light-bg">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
              <div
                className="bg-cover vh-100 ml-n3 background-img"
                style={{ background: "url(assets/img/hero-bg-3.jpg)" }}
              >
                <div className="position-absolute login-signup-content">
                  <div className="position-relative text-white col-md-12 col-lg-7">
                    <h2 className="text-white">Change Your Password</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6">
              <div className="login-signup-wrap px-4 px-lg-5 my-5">
                <h1 className="text-center mb-1">Change Password</h1>

                <p className="text-muted text-center mb-5">
                  Enter your new password
                </p>

                <form
                  className="login-signup-form"
                  id="resetForm"
                  onSubmit={changePassword}
                >
                  <div className="form-group">
                    <label className="pb-1">New Password</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-lock color-primary"></span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New password"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="pb-1">Confirm Password</label>
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-lock color-primary"></span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm password"
                        name="re_password"
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                    type="submit"
                    style={{ height: 50 }}
                    disabled={loading}
                  >
                    {loading ? (
                      <div class="spinner-grow"></div>
                    ) : (
                      "Change Password"
                    )}
                  </button>

                  <div className="text-center">
                    <small className="text-muted text-center">
                      Remember your password? <Link to="/login">Log in</Link>.
                    </small>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
