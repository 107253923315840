import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Grid, Button, Box } from "@mui/material";
import Template from "./components/Template";
import VideoPlayer from "./components/VideoPlayer";
import { app } from "../constants";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import axios from "axios";
import { getHeaders } from "../providers/dataProvider";

const Lessons = () => {
  const params = useParams();
  const [section, setSection] = useState({});
  const [lesson, setLesson] = useState({});
  const [course, setCourse] = useState({});
  const [nextCategory, setNextCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const markAsComplete = () => {
    setCompleted(true);
    const url = `${app.api}lessons/mark-as-complete?lesson_id=${lesson.id}&course_id=${course.id}`;
    axios
      .get(url, getHeaders())
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (course.user_lessons.length + 1 == course.all_lessons.length) {
          notify("Congratulations, you have completed all lessons.", {
            type: "success",
          });
          if (course.has_credentials.length == 0) navigate("/my_courses");
          else navigate(`/my_courses/${params.course_id}/finish`);
        }
      });
  };
  //
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await dataProvider.getOne("sections", {
          id: params.section_id,
          query: {
            complete_fetch: "lessons",
          },
        });
        let _lesson = (data?.lessons || []).filter(
          (l) => l.id == parseInt(params.lesson_id)
        );
        if (_lesson.length == 1) {
          _lesson = _lesson[0];
          setLesson(_lesson);
        } else {
          throw null;
        }
        let _course = await dataProvider.getOne("courses", {
          id: params.course_id,
        });
        _course = _course.data;
        setCourse(_course);
        setSection(data);
        //
        let sections = _course?.sections || [];
        const currentSectionIndex = sections.findIndex((it) => it == data.id);
        if (currentSectionIndex != -1) {
          let nextSection = currentSectionIndex + 1;
          if (nextSection <= sections.length - 1) {
            setNextCategory(
              `/my_courses/${params.course_id}/section/${sections[nextSection]}`
            );
          }
        }
        //
        setCompleted((_course.user_lessons || []).includes(_lesson.id));
        setLoading(false);
      } catch (error) {
        notify("Sorry, lesson not found", { type: "error" });
        navigate("/my_courses");
      }
    })();
  }, [window.location.hash]);
  //
  const Lesson = ({ id, image, title, index }) => {
    const active = lesson.id == id;
    return (
      <div
        style={{
          padding: "10px",
          ...(active && {
            backgroundColor: app.colorOne,
          }),
          borderRadius: 8,
          margin: "0px 10px",
        }}
        className="d-flex center-view highlight-on-hover"
        onClick={() =>
          navigate(
            window.location.hash
              .replace(/^#/, "")
              .replace(/\/lesson\/\d+/, `/lesson/${id}`)
          )
        }
      >
        <div
          style={{
            width: 20,
          }}
        >
          {active ? (
            <PlayArrowIcon
              fontSize="small"
              sx={{
                color: "white",
              }}
            />
          ) : (
            <h6
              className="mb-0"
              style={{
                fontSize: 14,
                textAlign: "center",
                color: active ? "white" : "black",
              }}
            >
              {index}
            </h6>
          )}
        </div>
        {image?.src && (
          <div
            style={{
              width: 80,
              height: 53.33,
              backgroundImage: `url('${image.src}')`,
              border: "1px solid",
              borderColor: active ? "white" : app.colorOne,
              backgroundSize: "cover",
            }}
            className="course-img"
          ></div>
        )}
        <div className="flex-1">
          <h6
            className="mb-0"
            style={{
              paddingLeft: 10,
              fontSize: 14,
              overflowWrap: "anywhere",
              color: active ? "white" : "black",
            }}
          >
            {title}
          </h6>
        </div>
      </div>
    );
  };
  //
  return (
    <Template contentLoading={loading}>
      {section?.lessons && lesson && (
        <>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={9}>
              <Grid item xs={12}>
                {lesson?.video_link ? (
                  <VideoPlayer video_id={lesson.video_link} />
                ) : (
                  <Card>
                    <div
                      style={{
                        height: 483,
                        width: "100%",
                        backgroundImage: `url('${lesson.image.src}')`,
                        backgroundSize: "contain",
                      }}
                      className="course-img"
                    ></div>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <Card sx={{ padding: 2 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: lesson?.description,
                    }}
                  ></div>
                </Card>
                <Box mt={2}>
                  <Button
                    disabled={completed}
                    color="primary"
                    variant="contained"
                    sx={{ width: "100%" }}
                    size="large"
                    onClick={markAsComplete}
                  >
                    {completed ? "COMPLETED" : "MARK AS COMPLETE"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} style={{}}>
              <Card
                sx={{
                  padding: "10px 0px",
                  overflowY: "auto",
                  height: 483,
                }}
              >
                {section.lessons.map((lesson, index) => (
                  <Lesson key={index} {...lesson} index={index + 1} />
                ))}
                {nextCategory && (
                  <div
                    style={{ margin: 10, marginTop: 15 }}
                    className="d-flex center-view"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => navigate(nextCategory)}
                    >
                      Next Section
                    </Button>
                  </div>
                )}
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Template>
  );
};

export default Lessons;
