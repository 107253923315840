import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  EditButton,
  Edit,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Filter,
  useDataProvider,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import UserIcon from "@mui/icons-material/PeopleOutline";
import { app } from "../constants";
import { createUser } from "../utils";
import ExtendedPagination from "../components/ExtendedPagination";
import DialogDelete from "../components/DialogDelete";
import { FormControl, Button, MenuItem, Select } from "@mui/material";

const BulkAssignUserGroup = ({ selectedIds }) => {
  const [userGroups, setUserGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("-1");
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselect = useUnselectAll("all_users");
  //
  const handleChange = (event) => {
    setSelectedGroup(event.target.value);
  };
  const handleSave = async () => {
    if (!selectedGroup || selectedGroup == "-1") {
      notify("Please choose any user group to continue", { type: "error" });
      return;
    }
    setLoading(true);
    await dataProvider.updateMany("all_users", {
      ids: selectedIds,
      data: { group_id: selectedGroup },
    });
    setSelectedGroup("-1");
    unselect();
    setLoading(false);
    refresh();
  };
  //
  useEffect(() => {
    dataProvider
      .getList("user_groups", {
        pagination: { page: 1, perPage: 100 },
      })
      .then(({ data }) => {
        let _data = [...data];
        _data.unshift({
          id: -2,
          title: "Unassign",
        });
        setUserGroups(_data);
        setLoading(false);
      });
  }, []);
  return (
    <FormControl sx={{ flexDirection: "row" }} size="small">
      <Select
        value={selectedGroup}
        onChange={handleChange}
        variant="outlined"
        size="small"
        sx={{
          minWidth: 200,
          fontSize: 12,
          color: "black",
        }}
      >
        <MenuItem value="-1" disabled>
          Choose User Group
        </MenuItem>
        {userGroups.map((group, idx) => (
          <MenuItem key={idx} value={group.id}>
            {group.title}
          </MenuItem>
        ))}
      </Select>
      <Button
        sx={{
          ml: 1,
          borderColor: "#a5aab1",
          textTransform: "none",
          color: "black",
        }}
        size="small"
        variant="outlined"
        onClick={handleSave}
        disabled={selectedGroup == "-1" || loading}
      >
        Bulk Assign
      </Button>
    </FormControl>
  );
};
const UserBulkActionToolbar = ({ selectedIds }) => {
  return <BulkAssignUserGroup selectedIds={selectedIds} />;
};
const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search Email"
      source="email"
      fullWidth={true}
      variant="outlined"
      alwaysOn
    />
    <ReferenceInput
      source="user_group"
      reference="user_groups"
      fullWidth
      alwaysOn
      perPage={10000000}
    >
      <SelectInput optionText={"title"} variant="outlined" label="User Group" />
    </ReferenceInput>
  </Filter>
);
const UserList = (props) => (
  <List
    {...props}
    pagination={<ExtendedPagination />}
    resource="all_users"
    filters={<UserFilter />}
  >
    <Datagrid bulkActionButtons={<UserBulkActionToolbar />}>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <ReferenceField
        source="user_group"
        reference="user_groups"
        emptyText="--"
      >
        <TextField source="title" />
      </ReferenceField>
      <TextField source="role" />
      <EditButton />
      <DialogDelete model={"all_users"} />
    </Datagrid>
  </List>
);
const UserCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const submitUser = async (data) => {
    const { name, email, password, role, user_group } = data;
    try {
      await createUser({ name, email, password, role, user_group });
      notify("User created successfully.", {
        type: "success",
      });
      redirect("/all_users");
    } catch (error) {
      let response = error.response.data;
      let email_errors = response?.email || [];
      let password_errors = response?.password || [];
      notify([...email_errors, ...password_errors].join("."), {
        type: "error",
      });
    }
  };
  return (
    <Create>
      <SimpleForm onSubmit={submitUser}>
        <TextInput {...app.inputAttrs} source="name" />
        <TextInput {...app.inputAttrs} source="email" type="email" />
        <TextInput {...app.inputAttrs} source="password" type="password" />
        <SelectInput
          {...app.inputAttrs}
          source="role"
          choices={[
            { id: "admin", name: "Admin" },
            { id: "user", name: "User" },
          ]}
        />
        <ReferenceInput reference="user_groups" source="user_group">
          <SelectInput optionText="title" {...app.inputAttrs} validate={[]} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
const UserEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput {...app.inputAttrs} source="name" />
        <SelectInput
          {...app.inputAttrs}
          source="role"
          choices={[
            { id: "admin", name: "Admin" },
            { id: "user", name: "User" },
          ]}
        />
        <ReferenceInput reference="user_groups" source="user_group">
          <SelectInput optionText="title" {...app.inputAttrs} validate={[]} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
export default {
  list: UserList,
  edit: UserEdit,
  name: "all_users",
  icon: UserIcon,
  label: "Users",
  create: UserCreate,
};
