import React from "react";
import { List, Datagrid, TextField, EmailField } from "react-admin";
import ContactIcon from "@mui/icons-material/PeopleOutline";

const ContactList = (props) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="message" />
    </Datagrid>
  </List>
);
export default {
  list: ContactList,
  name: "contacts",
  icon: ContactIcon,
  label: "Proposal Requests",
};
