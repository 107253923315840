import React from "react";
//
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNotify } from "react-admin";
//
const StartTest = ({ question, setState, style = {} }) => {
  const notify = useNotify();
  return (
    <>
      {Object.keys(style).length == 0 ? (
        <Typography
          variant="p"
          component="div"
          align="left"
          sx={{ marginTop: 2, marginBottom: 2, marginLeft: 2 }}
        >
          Sorry, no assessment style found.
        </Typography>
      ) : (
        <>
          <CardContent>
            <Typography variant="h5" component="div" align="center">
              Welcome to the {style.title}!
            </Typography>
            <Typography variant="body1" sx={{ mt: 1.5 }} align="left">
              <div
                dangerouslySetInnerHTML={{
                  __html: style.description,
                }}
              ></div>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              onClick={() => {
                if (Object.keys(question).length == 0)
                  notify("No question found, Please try later!!!", {
                    type: "error",
                  });
                else setState(1);
              }}
            >
              Start Test
            </Button>
          </CardActions>
        </>
      )}
    </>
  );
};

export default StartTest;
