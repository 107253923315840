import React from "react";
import { Datagrid, TextField, ArrayField } from "react-admin";

const OptionsView = ({
  source,
  leftLable = "Left Option Text",
  rightLable = "Right Option Text",
}) => {
  return (
    <ArrayField source={source}>
      <Datagrid bulkActionButtons={false} empty="--">
        <TextField source="left" label={leftLable} />
        <TextField source="right" label={rightLable} />
      </Datagrid>
    </ArrayField>
  );
};
export default OptionsView;
