import { Card, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import ErrorIcon from "@mui/icons-material/Error";

const VideoPlayer = ({ video_id }) => {
  const [videoLoading, setVideoLoading] = useState(true);
  const [videoError, setVideoError] = useState(false);
  return (
    <Card>
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <ReactPlayer
          url={"https://vimeo.com/" + video_id}
          controls
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onReady={() => setVideoLoading(false)}
          onError={() => setVideoError(true)}
        />
        {videoLoading && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "white",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              opacity: 1,
            }}
            className="d-flex center-view"
          >
            {videoError ? (
              <div
                style={{
                  flexDirection: "column",
                }}
                className="d-flex center-view"
              >
                <ErrorIcon
                  sx={{
                    fontSize: 75,
                    marginBottom: 1.3,
                  }}
                />
                <h5>Error!</h5>
              </div>
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
export default VideoPlayer;
