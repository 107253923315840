import React from "react";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  Edit,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  EditButton,
} from "react-admin";
import QuestionIcon from "@mui/icons-material/Quiz";
import { app } from "../constants";
import OptionsView from "../components/OptionsView";
import DuplicateButton from "../components/DuplicateButton";

const QuestionsList = (props) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="question_1" label="Scenario#1 Question" />
      <OptionsView source="options_1" />
      <TextField source="question_2" label="Scenario#2 Question" />
      <OptionsView source="options_2" />
      <ReferenceField source="style" reference="styles">
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
      <DuplicateButton model={"questions"} />
    </Datagrid>
  </List>
);
const OptionsCreate = ({ source, label }) => {
  return (
    <ArrayInput source={source} label={label}>
      <SimpleFormIterator inline fullWidth>
        <TextInput
          source="left"
          helperText={false}
          {...app.inputAttrs}
          label="Left Option Text"
          style={{ flex: 1 }}
        />
        <TextInput
          source="right"
          helperText={false}
          {...app.inputAttrs}
          label="Right Option Text"
          style={{ flex: 1 }}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
const QuestionForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      source="question_1"
      {...app.inputAttrs}
      label="Scenario#1 Question"
    />
    <OptionsCreate source="options_1" label="Scenario#1 Options" />
    <TextInput
      source="question_2"
      {...app.inputAttrs}
      label="Scenario#2 Question"
    />
    <OptionsCreate source="options_2" label="Scenario#2 Options" />
    <ReferenceInput source="style" reference="styles">
      <SelectInput optionText="title" {...app.inputAttrs} />
    </ReferenceInput>
  </SimpleForm>
);
const QuestionCreate = (props) => (
  <Create {...props}>
    <QuestionForm />
  </Create>
);
const QuestionEdit = (props) => (
  <Edit {...props}>
    <QuestionForm />
  </Edit>
);
export default {
  list: QuestionsList,
  create: QuestionCreate,
  edit: QuestionEdit,
  name: "questions",
  icon: QuestionIcon,
  label: "Questions",
};
