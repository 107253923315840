import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  EditButton,
  useRecordContext,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import StyleIcon from "@mui/icons-material/Highlight";
import { app } from "../constants";
import DuplicateButton from "../components/DuplicateButton";

const StyleList = (props) => {
  const HtmlField = ({ source }) => {
    const record = useRecordContext();
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: record[source],
        }}
      ></div>
    );
  };
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="slug" label={"Slug"} />
        <TextField source="title" />
        <HtmlField source="description" />
        <EditButton />
        <DuplicateButton model={"styles"} />
      </Datagrid>
    </List>
  );
};
const StyleForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="slug" {...app.inputAttrs} label="Slug" />
      <TextInput source="title" {...app.inputAttrs} />
      <RichTextInput
        helperText=""
        source={"description"}
        {...{ fullWidth: true, variant: "outlined" }}
        label={"Description"}
      />
    </SimpleForm>
  );
};
const StyleEdit = (props) => (
  <Edit {...props}>
    <StyleForm {...props} />
  </Edit>
);
const StyleCreate = (props) => (
  <Create {...props}>
    <StyleForm {...props} />
  </Create>
);
export default {
  list: StyleList,
  create: StyleCreate,
  edit: StyleEdit,
  name: "styles",
  icon: StyleIcon,
  label: "Assessment",
};
