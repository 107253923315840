import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  TextInput,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from "react-admin";
import CompletionIcon from "@mui/icons-material/Stars";
import { Card } from "@mui/material";
import { app } from "../constants";

const NoCompletions = () => {
  return (
    <div className="mt-3 w-100">
      <Card
        sx={{
          padding: 2,
        }}
      >
        Sorry, no course completions found.
      </Card>
    </div>
  );
};
const CompletionList = (props) => {
  const { permissions } = usePermissions();
  const courseFilter = (
    <ReferenceInput
      source="course"
      reference="courses"
      fullWidth
      alwaysOn
      perPage={9999999}
    >
      <SelectInput optionText={"title"} variant="outlined" label="Course" />
    </ReferenceInput>
  );
  return (
    <List
      {...props}
      resource="courses/completions"
      empty={<NoCompletions />}
      filters={
        ["admin", "superadmin"].includes(permissions?.role)
          ? [
              <TextInput source="username" {...app.filterAttrs} alwaysOn />,
              <ReferenceInput
                source="user_group"
                reference="user_groups"
                fullWidth
                alwaysOn
                perPage={9999999}
              >
                <SelectInput
                  optionText={"title"}
                  variant="outlined"
                  label="User Group"
                />
              </ReferenceInput>,
              courseFilter,
            ]
          : [courseFilter]
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="user" />
        <TextField source="user_group" />
        <TextField source="course" />
        <FunctionField
          source="percentage"
          render={(record) => `${record.percentage} %`}
        />
        <NumberField source="lessons_completed" label="Lessons Completed" />
        <NumberField source="total_lessons" label="Total Lessons" />
      </Datagrid>
    </List>
  );
};

export default {
  list: CompletionList,
  name: "completions",
  icon: CompletionIcon,
  label: "Completions",
};
