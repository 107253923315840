import React, { useEffect, useState } from "react";
import Template from "./components/Template";
import { useDataProvider, useNotify } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import CourseProgress from "./components/CourseProgress";
import { extractPlainTextFromHTML } from "../utils";

const Sections = () => {
  const params = useParams();
  const [section, setSection] = useState({});
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await dataProvider.getOne("sections", {
          id: params.section_id,
          query: {
            complete_fetch: "lessons",
          },
        });
        const _course = await dataProvider.getOne("courses", {
          id: params.course_id,
        });
        setSection(data);
        setCourse(_course.data);
        setLoading(false);
      } catch (error) {
        notify("Sorry, course or section not found", { type: "error" });
        navigate("/my_courses");
      }
    })();
  }, []);
  const Lesson = ({ id, image, title, description, short_summary = "" }) => {
    return (
      <div
        className="course-card highlight-on-hover row align-items-center"
        style={{ minHeight: 120 }}
        onClick={() => navigate("lesson/" + id)}
      >
        <div className="col-12 col-lg-3">
          <div
            style={{
              width: "100%",
              height: 120,
              backgroundImage: `url('${image?.src}')`,
            }}
            className="course-img"
          ></div>
        </div>
        <div className="col-12 col-lg-9">
          <h5 className="mb-1">{title}</h5>
          <div className="overflow-text">
            {extractPlainTextFromHTML(short_summary || description)}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Template
        title={section?.title}
        contentLoading={loading}
        headerLoading={loading}
      >
        {section?.lessons && course?.sections && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Card>
                  {section.lessons.map((lesson, index) => (
                    <Lesson key={index} {...lesson} />
                  ))}
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <CourseProgress course={course} />
              </Grid>
            </Grid>
          </>
        )}
      </Template>
    </>
  );
};

export default Sections;
