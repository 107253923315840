import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Button,
  useRecordContext,
  DateField,
  useDataProvider,
  usePermissions,
  downloadCSV,
  TopToolbar,
  useListContext,
  BooleanField,
  useRefresh,
  useUnselectAll,
  SelectInput,
  ShowButton,
  Show,
  SimpleShowLayout,
  useRedirect,
  NumberField,
  TextInput,
} from "react-admin";
import ReportView from "@mui/icons-material/RemoveRedEye";
import GraphicEqIcon from "@mui/icons-material/Equalizer";
import ReportIcon from "@mui/icons-material/Assessment";
import { getQueryParams, getReportUrl, isAdmin } from "../utils";
import OptionsView from "../components/OptionsView";
import GraphViewer from "../components/Dialog";
import jsonExport from "jsonexport/dist";
//
import klsa_chart from "./klsa_chart.jpg";
import kmsa_chart from "./kmsa_chart.jpg";
import ktsa_chart from "./ktsa_chart.jpg";
import { Download } from "@mui/icons-material";
import ExtendedPagination from "../components/ExtendedPagination";
import DialogDelete from "../components/DialogDelete";
import { app } from "../constants";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { getHeaders } from "../providers/dataProvider";
const charts = {
  klsa: klsa_chart,
  kmsa: kmsa_chart,
  ktsa: ktsa_chart,
};
const reportFilters = [
  <SelectInput
    source="status"
    choices={[
      { id: "In-Progress", name: "In-Progress" },
      { id: "Completed", name: "Completed" },
    ]}
    alwaysOn
    {...app.filterAttrs}
  />,
  <TextInput source="group" alwaysOn {...app.filterAttrs} />,
  <TextInput
    source="link_group"
    label="Link Group"
    alwaysOn
    {...app.filterAttrs}
  />,
];
const ReportList = (props) => {
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const userIsAdmin = isAdmin(permissions?.role);
  const [styles, setStyles] = useState([]);
  const unselectAll = useUnselectAll("reports");
  const refresh = useRefresh();
  //
  const exporter = async (reports) => {
    const params = getQueryParams();
    const page = params.get("page") || 1;
    const perPage = params.get("perPage") || 0;
    const { data } = await dataProvider.getList("all_users", {
      pagination: { page: 1, perPage: 2000 },
      sort: {
        field: "id",
        order: "ASC",
      },
    });
    let users = data.reduce((acc, { id, email }) => {
      acc[id] = { email_website: email.split("@")[1] };
      return acc;
    }, {});
    let start = (parseInt(page) - 1) * parseInt(perPage) + 1;
    const reports_to_export = reports.map((report) => ({
      sr: start++,
      id: report.report_id,
      user: report?.user_id,
      style: styles[report.style_id].slug.toLocaleUpperCase(),
      group: report.group || "--",
      link_group: report.link_group || "--",
      created_at: report.created_at.replace("T", " ").replace("Z", ""),
      x_score: report.xScore,
      y_score: report.yScore,
      email_website:
        report?.user_id && users[report.user_id]
          ? users[report.user_id].email_website
          : "",
    }));
    jsonExport(
      reports_to_export,
      {
        headers: [
          "sr",
          "id",
          "user",
          "style",
          "group",
          "x_score",
          "y_score",
          "created_at",
          "email_website",
        ],
      },
      async (err, csv) => {
        downloadCSV(csv, "reports_mykineticstyle.com");
        // Update in DB that these report are exported
        await dataProvider.updateMany("reports", {
          ids: reports.map((report) => report.id),
          data: {},
        });
        unselectAll();
        refresh();
      }
    );
  };
  //
  const MyExportButtons = () => {
    const { data } = useListContext();
    if (userIsAdmin)
      return (
        <TopToolbar>
          <Button
            label="Export"
            onClick={() => exporter(data)}
            startIcon={<Download />}
          />
        </TopToolbar>
      );
    return <></>;
  };
  const BulkEditReportButton = ({ selectedIds }) => {
    const { data } = useListContext();
    return (
      <Button
        startIcon={<Download />}
        onClick={() =>
          exporter(data.filter((it) => selectedIds.includes(it.id)))
        }
        label={"Export"}
      />
    );
  };
  const ReportBulkActions = ({ selectedIds }) => (
    <BulkEditReportButton selectedIds={selectedIds} />
  );
  const ViewPdfButton = ({}) => {
    const report = useRecordContext();
    return (
      <Button
        endIcon={<ReportView />}
        onClick={() => {
          const url = getReportUrl(report.report_id);
          window.open(url);
        }}
        style={{
          fontSize: 12,
        }}
      >
        View Report
      </Button>
    );
  };
  const ShowGraphButton = ({}) => {
    const { xScore, yScore, style_id } = useRecordContext();
    const [open, setOpen] = useState(false);
    return (
      <>
        <GraphViewer
          open={open}
          setOpen={setOpen}
          xScore={xScore}
          yScore={yScore}
          chart={charts[styles[style_id].slug]}
        />
        <Button
          endIcon={<GraphicEqIcon />}
          onClick={() => {
            setOpen(true);
          }}
          style={{
            fontSize: 12,
          }}
        >
          View Graph
        </Button>
      </>
    );
  };
  const ReportStatus = () => {
    const [loading, setLoading] = useState(false);
    const record = useRecordContext();
    const refresh = useRefresh();
    const retry = async () => {
      setLoading(true);
      let url = app.api + "reports/retry/";
      Promise.resolve(
        axios.post(
          url,
          { report_id: record.report_id },
          { ...getHeaders(), timeout: 90000 }
        )
      )
        .then(() => {
          refresh();
        })
        .catch(() => {
          refresh();
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (
      (record.status == "In-Progress" &&
        (new Date() - new Date(record.created_at)) / 1000 > 20) ||
      ["--", "", null].includes(record.status)
    )
      return (
        <center>
          <Button onClick={retry} variant="outlined" color="error">
            {loading ? <CircularProgress size={20} /> : "Regenerate Report"}
          </Button>
        </center>
      );
    return <TextField source="status" emptyText="--" />;
  };
  //
  useEffect(() => {
    (async () => {
      const { data } = await dataProvider.getList("styles", {
        pagination: { page: 1, perPage: 1000 },
        sort: {},
      });
      setStyles(
        data.reduce((acc, { id, slug }) => {
          acc[id] = { slug };
          return acc;
        }, {})
      );
    })();
  }, []);
  return (
    <List
      {...props}
      actions={<MyExportButtons {...props} />}
      exporter={exporter}
      filters={userIsAdmin && reportFilters}
      pagination={<ExtendedPagination />}
    >
      <Datagrid bulkActionButtons={userIsAdmin ? <ReportBulkActions /> : <></>}>
        {userIsAdmin && <BooleanField source="exported" />}
        <TextField source="user_name" label="User Name" emptyText="--" />
        <TextField source="style_title" label="Style" emptyText="--" />
        <TextField source="thinking_style" emptyText="--" label="Result" />
        {userIsAdmin && <NumberField source="xScore" />}
        {userIsAdmin && <NumberField source="yScore" />}
        {userIsAdmin && <TextField source="group" emptyText="--" />}
        {userIsAdmin && (
          <TextField source="link_group" label="Link Group" emptyText="--" />
        )}
        <ReportStatus label="Status" />
        <DateField source="created_at" showTime />
        {userIsAdmin && <ShowButton label="Complete View" />}
        <ViewPdfButton />
        {Object.values(styles).length > 0 && <ShowGraphButton />}
        {userIsAdmin && <DialogDelete model="reports" />}
      </Datagrid>
    </List>
  );
};

export const ReportShow = () => {
  const { permissions } = usePermissions();
  const redirect = useRedirect();
  useEffect(() => {
    if (!isAdmin(permissions?.role)) redirect("list");
  }, [permissions]);
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="report_id" />
        <TextField source="user_name" label="Name" />
        <TextField source="style_title" label="Style" />
        <TextField source="thinking_style" emptyText="--" label="Result" />
        {isAdmin(permissions?.role) && (
          <OptionsView
            source="shuffled_question.options_1"
            label="Scenario#1 Options"
            leftLable="Left Option"
            rightLable="Right Option"
          />
        )}
        {isAdmin(permissions?.role) && (
          <OptionsView
            source="shuffled_question.options_2"
            label="Scenario#2 Options"
            leftLable="Left Option"
            rightLable="Right Option"
          />
        )}
        <DateField source="created_at" />
      </SimpleShowLayout>
    </Show>
  );
};
export default {
  list: ReportList,
  show: ReportShow,
  name: "reports",
  icon: ReportIcon,
  label: "Reports",
};
