import * as React from "react";

import {
  Card,
  CardContent,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField as MaterialTextField,
  Grid,
} from "@mui/material";

import { Title } from "react-admin";
import { useNotify, useAuthProvider } from "react-admin";
import axios from "axios";

import {
  Lock as LockIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { passwordValidator, app } from "../constants";
import { getHeaders } from "../providers/dataProvider";

const Password = () => {
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);
  const authProvider = useAuthProvider();
  const error = (string, type = "error") => {
    notify(string, { type });
  };
  const [values, setValues] = React.useState({
    password: "",
    confirmPassword: "",
    currentPassword: "",
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const changePassword = (e) => {
    e.preventDefault();
    const { password, confirmPassword, currentPassword } = values;
    authProvider.getIdentity().then(async (user) => {
      if (passwordValidator(password, "New ")) {
        error(passwordValidator(password, "New "));
        return;
      }
      if (passwordValidator(confirmPassword, "Confirm New ")) {
        error(passwordValidator(confirmPassword, "Confirm New "));
        return;
      }
      if (passwordValidator(currentPassword, "Current ")) {
        error(passwordValidator(currentPassword, "Current "));
        return;
      }
      setLoading(true);
      let url = app.api + "users/set_password/";
      await axios
        .post(
          url,
          {
            new_password: password,
            re_new_password: confirmPassword,
            current_password: currentPassword,
          },
          getHeaders()
        )
        .then((data) => {
          error("Password changed successfully.", "success");
          setValues({
            password: "",
            confirmPassword: "",
            confirmPassword: "",
            showPassword: false,
          });
          window.location = "#/";
        })
        .catch((er) => {
          let errors = [].concat(...Object.values(er.response.data));
          error([...errors].join(""));
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return (
    <Card
      style={{
        marginTop: 20,
      }}
    >
      <Title title={"Manage Password"} />
      <CardContent>
        <form onSubmit={changePassword}>
          <FormControl fullWidth>
            <Grid container>
              <Grid item xs={12}>
                <MaterialTextField
                  fullWidth
                  autoFocus
                  label="Current Password"
                  variant="outlined"
                  size="small"
                  type={values.showPassword ? "text" : "password"}
                  value={values.currentPassword}
                  required
                  onChange={handleChange("currentPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    marginBottom: 20,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialTextField
                  fullWidth
                  label="New Password"
                  variant="outlined"
                  size="small"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  required
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    marginBottom: 20,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialTextField
                  fullWidth
                  label="Confirm New Password"
                  variant="outlined"
                  size="small"
                  type={values.showPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  required
                  onChange={handleChange("confirmPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    marginBottom: 20,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  startIcon={<LockIcon />}
                  variant="contained"
                  color="primary"
                  onClick={changePassword}
                  type="submit"
                  style={{ height: 50, width: 200 }}
                  disabled={loading}
                >
                  {loading ? (
                    <div class="spinner-grow"></div>
                  ) : (
                    "Change Password"
                  )}
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
};

export default Password;
