import { stringify } from "query-string";
import { app } from "../constants";
import axios from "axios";
const apiUrl = app.api;
const httpClient = axios;

export const getHeaders = () => {
  let auth = JSON.parse(localStorage.getItem("auth"));
  try {
    return {
      headers: {
        Authorization: "JWT " + auth.token,
      },
    };
  } catch (error) {
    return {};
  }
};
const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params?.sort || { field: "id", order: "ASC" };
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}${resource}/?${stringify(query)}`;
    return httpClient(url, getHeaders()).then((response) => {
      const { data } = response;
      return {
        data: data.results,
        total: data.count,
      };
    });
  },

  getOne: (resource, params) => {
    let query = stringify(params?.query || {});
    if (query) query = "?" + query;
    const url = `${apiUrl}${resource}/${params.id}/${query}`;
    return httpClient.get(url, getHeaders()).then((response) => ({
      data: response.data,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}${resource}?${stringify(query)}`;
    return httpClient.get(url, getHeaders()).then((response) => {
      const { data } = response;
      return {
        data: data.results,
        total: data.count,
      };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}${resource}?${stringify(query)}`;

    return httpClient.get(url, getHeaders()).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  delete: (resource, params) =>
    httpClient
      .delete(`${apiUrl}${resource}/${params.id}`, getHeaders())
      .then(({ json }) => ({ data: json })),

  create: (resource, params) => {
    return httpClient
      .post(`${apiUrl}${resource}/`, params.data, getHeaders())
      .then((response) => {
        return {
          data: { ...params.data, id: response.id },
        };
      });
  },

  update: (resource, params) => {
    const url = `${apiUrl}${resource}/${params.id}/`;
    return httpClient.put(url, params.data, getHeaders()).then((response) => {
      return {
        data: response.data,
      };
    });
  },
  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}${resource}/0/?${stringify(query)}`;
    return httpClient
      .put(url, JSON.stringify(params.data), getHeaders())
      .then((response) => {
        return {
          data: [],
        };
      });
  },
};

export default dataProvider;
