import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GraphViewer({ open, setOpen, chart, xScore, yScore }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Graph
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ position: "relative" }}>
          <img
            src={chart}
            className="img-fluid"
            style={{
              width: 460,
              height: 445,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 210 + -(yScore * 18),
              left: 224 + xScore * 18,
            }}
          >
            &#9679;
            <br />
            <div
              style={{
                marginTop: -5,
                textAlign: "center",
                fontSize: 10,
                fontWeight: "bold",
                marginLeft: -12,
              }}
            >
              {xScore + ":" + yScore}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
