import { Delete } from "@mui/icons-material";
import { useState } from "react";
import { Button, Confirm, useRecordContext, useDelete } from "react-admin";

const DialogDelete = ({ model }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  const [remove, { isLoading }] = useDelete(model, {
    id: record && record.id,
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    remove();
    setOpen(false);
  };

  return (
    <>
      <Button
        label="Delete"
        onClick={handleClick}
        color="error"
        startIcon={<Delete />}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Delete Confirmation`}
        content="Are you sure you want to delete this item?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default DialogDelete;
