import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { validateEmail } from "../../utils";
import { useNotify } from "react-admin";
import { CircularProgress } from "@mui/material";
import { app } from "../../constants";
import axios from "axios";
import { getHeaders } from "../../providers/dataProvider";
import { useNavigate } from "react-router-dom";

export default function FinishCourseForms({ course_id }) {
  const notify = useNotify();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") || "";
    const re_email = data.get("re_email") || "";
    if (email.length == 0 || re_email.length == 0)
      return notify("Please provide all values to continue.", {
        type: "error",
      });
    if (!validateEmail(email) || !validateEmail(re_email))
      return notify("Please provide valid emails.", {
        type: "error",
      });
    if (email != re_email)
      return notify("Both emails should be same.", {
        type: "error",
      });
    setLoading(true);
    // Call Accredible API
    const url = `${app.api}courses/create-accredible-credentials/`;
    axios
      .post(
        url,
        {
          course_id,
          email,
        },
        getHeaders()
      )
      .then(({ data }) => {
        if (data?.created) {
          notify("Accredible Credentials is successfully created.", {
            type: "success",
          });
          navigate("/credentials");
        } else throw null;
      })
      .catch(() => {
        notify("Something went wrong.", { type: "error" });
        navigate("/");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <CardMembershipIcon />
        </Avatar>
        <Typography sx={{ marginTop: 2 }} component="h1" variant="body1">
          Enter email address to create Accredible Credentials
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            variant="outlined"
            type="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Retype Email Address"
            name="re_email"
            variant="outlined"
            type="email"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, height: 42 }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size="2rem" />
            ) : (
              "Create Credentials"
            )}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
