import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  EditButton,
  DeleteButton,
} from "react-admin";
import UserGroupIcon from "@mui/icons-material/Groups";
import { app } from "../constants";
import DialogDelete from "../components/DialogDelete";

const transformData = (data) => {
  data.slug = data.title
    .replace(/[^a-zA-Z0-9]+/g, " ")
    .replace(/ /g, "_")
    .trim()
    .toLowerCase();
  return data;
};
const UserGroupList = (props) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="title" />
        <TextField source="slug" />
        <EditButton />
        <DialogDelete model={"user_groups"} />
      </Datagrid>
    </List>
  );
};
const UserGroupForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="title" {...app.inputAttrs} />
    </SimpleForm>
  );
};
const UserGroupEdit = (props) => (
  <Edit {...props} transform={transformData}>
    <UserGroupForm {...props} />
  </Edit>
);
const UserGroupCreate = (props) => (
  <Create {...props} transform={transformData}>
    <UserGroupForm {...props} />
  </Create>
);
export default {
  list: UserGroupList,
  create: UserGroupCreate,
  edit: UserGroupEdit,
  name: "user_groups",
  icon: UserGroupIcon,
  label: "User Groups",
};
