import * as React from "react";
import { Fragment } from "react";
// import { useSelector } from "react-redux";
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from "@mui/material";
import { useSidebarState } from "react-admin";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { useTranslate } from "react-admin";

const classes = {
  icon: { minWidth: (theme) => theme.spacing(5) },
  sidebarIsOpen: {
    "& a": {
      paddingLeft: (theme) => theme.spacing(4),
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
  sidebarIsClosed: {
    "& a": {
      paddingLeft: (theme) => theme.spacing(2),
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
};

const SubMenu = ({
  handleToggle,
  isOpen,
  name,
  icon,
  children,
  dense,
  subMenu,
}) => {
  const translate = useTranslate();
  const [sidebarIsOpen, _] = useSidebarState();

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon sx={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <div
      style={{
        marginLeft: subMenu && sidebarIsOpen ? 20 : 0,
      }}
    >
      <Fragment>
        {sidebarIsOpen || isOpen ? (
          header
        ) : (
          <Tooltip title={translate(name)} placement="right">
            {header}
          </Tooltip>
        )}
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List
            dense={dense}
            component="div"
            disablePadding
            sx={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
          >
            {children}
          </List>
        </Collapse>
      </Fragment>
    </div>
  );
};

export default SubMenu;
